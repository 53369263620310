import { useEffect } from "react";
import axios from "axios";
import { GET_SIGNATURE } from "./api/lecture";
import { ZoomMtg } from "@zoomus/websdk";

const DOMAIN = process.env.REACT_APP_DOMAIN;
const SDK_KEY = process.env.REACT_APP_SDK_KEY;

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.5/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

export default function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const meetId = queryParameters.get("meetId");
  const redirect = queryParameters.get("redirect");
  console.log(meetId, redirect);

  // join meet
  let startMeeting = (data) => {
    let { signature, meetingNumber, passWord, userName } = data;
    let elem = document.getElementById("zmmtg-root");
    if (elem) {
      elem.style.display = "block";
    }

    ZoomMtg.init({
      leaveUrl: redirect,
      success: () => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: SDK_KEY,
          passWord: passWord,
          success: (success) => {
            console.log("success", success);
          },
          error: (err) => {
            console.error("join", err);
          },
        });
      },
      error: (error) => {
        console.error("init", error);
      },
    });
  };

  const joinHandler = () => {
    axios
      .get(GET_SIGNATURE(meetId))
      .then((res) => {
        startMeeting(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    joinHandler();
  }, []);

  return <></>;
}
